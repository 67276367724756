<template>
	<div>
		<div class="box box_mm">
			<div class="box_main">
				<div
					style="height: 70vh;overflow-y: auto;padding-right: 0.5rem;padding-bottom: 40px;box-sizing: border-box;">
					<el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="110px" class="demo-ruleForm">
						<el-form-item label="场地名称" prop="name">
							<el-input v-model="ruleForm.name" style="width: 6rem;"></el-input>
						</el-form-item>
						<el-form-item label="所属驿站" prop="stage_id">
							<el-select v-model="ruleForm.stage_id" placeholder="请选择" style="width: 3rem;">
								<el-option v-for="item in options" :key="item.id" :label="item.name" :value="item.id">
								</el-option>
							</el-select>
						</el-form-item>
						<el-form-item label="预约说明" prop="prompt">
							<el-input type="textarea" :autosize="{ minRows: 7, maxRows: 7}" style="width: 6rem;"
								v-model="ruleForm.prompt"></el-input>
						</el-form-item>
						<el-form-item label="场地缩略图" prop="image">
							<el-upload class="avatar-uploader" :action="baseurl + '/api/login/upload'" :show-file-list="false"
								:on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
								<img v-if="imageUrl" :src="imageUrl" class="avatar">
								<i v-else class="el-icon-plus avatar-uploader-icon"></i>
							</el-upload>
						</el-form-item>
						<el-form-item label="容纳人数" prop="capacity">
							<el-input v-model="ruleForm.capacity" style="width: 3rem;"></el-input>
						</el-form-item>
						<el-form-item label="详细地址" prop="venue_address">
							<el-input v-model="ruleForm.venue_address" style="width: 6rem;"></el-input>
						</el-form-item>

						<el-form-item label="开放日" required>
							<el-checkbox-group v-model="checkList">
								<el-checkbox label="1">周一</el-checkbox>
								<el-checkbox label="2">周二</el-checkbox>
								<el-checkbox label="3">周三</el-checkbox>
								<el-checkbox label="4">周四</el-checkbox>
								<el-checkbox label="5">周五</el-checkbox>
								<el-checkbox label="6">周六</el-checkbox>
								<el-checkbox label="0">周日</el-checkbox>
							</el-checkbox-group>
						</el-form-item>
						<el-form-item label="时间段" required>
							<div>
								<el-button type="primary" style="width: fit-content;" @click="addnewtime" size="mini">新增</el-button>
								
								<div style="display: flex;align-items: center;margin-bottom: 10px;" v-for="(item,index) in timelist" :key="index">
									<el-time-picker v-model="item.time1" placeholder="开始时间" style="width: 150px;" value-format="HH:MM"></el-time-picker>
									<div>
										&nbsp;&nbsp;至&nbsp;&nbsp;
									</div>
									<el-time-picker arrow-control v-model="item.time2" placeholder="截止时间" style="width: 150px;" value-format="HH:MM"></el-time-picker>
									<el-button v-show="timelist.length>1" type="danger" style="width: fit-content;margin-left: 10px;" @click="deletelist(index)" size="mini">删除</el-button>
								</div>
							</div>
							
						</el-form-item>
					</el-form>

				</div>

				<div style="display: flex;align-content: center;margin-top: 30px;">
					<el-button @click="backpage">取 消</el-button>
					<el-button type="primary" @click="submitForm">确 定</el-button>
				</div>
			</div>
		</div>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				baseurl: "",
				ruleForm: {
					name: "",
					stage_id: "",
					prompt: "",
					image: "",
					capacity: "",
					venue_address: "",
					time_range:'',
					week:''
				},
				rules: {
					name: [{
						required: true,
						message: '请输入场地名称',
						trigger: 'blur'
					}],
					stage_id: [{
						required: true,
						message: '请选择所属驿站',
						trigger: 'change'
					}],
					prompt: [{
						required: true,
						message: '请填写预约说明',
						trigger: 'blur'
					}],
					capacity: [{
						required: true,
						message: '请输入容纳人数',
						trigger: 'blur'
					}],
					image: [{
						required: true,
						message: '请上传缩略图',
						trigger: 'blur'
					}],
					venue_address: [{
						required: true,
						message: '请输入场地地址',
						trigger: 'blur'
					}]
				},
				dialogImageUrl: '',
				dialogVisible: false,
				imageUrl:'',
				options: [],
				checkList: [],
				timelist:[{time1:'',time2:''}]
			}
		},
		created() {
			this.baseurl = this.$URL

			this.$post({
				url: '/api/stage/index',
				params: {
					p: 1,
					size: 999
				}
			}).then((res) => {
				this.options = res.list
			})
			
			
			this.$post({
				url: '/api/stageactivityvenue/details',
				params: {
					id:this.$route.query.id
				}
			}).then((res) => {
				this.ruleForm = JSON.parse(JSON.stringify(res))
				this.ruleForm.image = res.image.id
				this.imageUrl = res.image.fullurl
				console.log(res)
				this.checkList = res.week.split(',')
				
				this.timelist = []
				let timelist = JSON.parse(res.time_range)
				timelist.forEach((item,index)=>{
					let obj = {
						time1:item[0],
						time2:item[1],
					}
					this.$set(this.timelist,this.timelist.length,obj)
				})
			})
		},
		methods: {
			addnewtime(){
				let obj = {
					time1:'',
					time2:''
				}
				
				this.$set(this.timelist,this.timelist.length,obj)
			},
			deletelist(index){
				this.timelist.splice(index,1)
			},
			submitForm() {
				if(this.checkList.length<=0){
					this.$message.warning('请选择开放日')
					return
				}
				if(!this.timelist[0].time1||!this.timelist[0].time2){
					this.$message.warning('请选择时间段')
					return
				}
				this.ruleForm.week = this.checkList.join(',')
				
				this.ruleForm.time_range = []
				this.timelist.forEach((item,index)=>{
					let list = [item.time1,item.time2]
					this.ruleForm.time_range.push(list)
				})
				
				this.$refs.ruleForm.validate((valid) => {
					if (valid) {
						console.log(this.ruleForm)
						this.$post({
							url: '/api/stageactivityvenue/edit',
							params: this.ruleForm
						}).then((res) => {
							this.$message.success('添加成功')
							this.$router.go(-1)
						})
					} else {
						console.log('error submit!!');
						return false;
					}
				})
			},
			openmap() {
				window.open('https://api.map.baidu.com/lbsapi/getpoint/index.html', '_blank')
			},
			handlePictureCardPreview(file) {
				this.dialogImageUrl = file.url;
				this.dialogVisible = true;
			},
			handleAvatarSuccess(res, file) {
				this.ruleForm.image = res.id
				this.imageUrl = URL.createObjectURL(file.raw);
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
				const isLt2M = file.size / 1024 / 1024 < 10;

				if (!isJPG) {
					this.$message.error('上传图片只能是 JPG或PNG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传图片大小不能超过 10MB!');
				}
				return isJPG && isLt2M;
			},
			backpage() {
				this.$router.go(-1)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.box_main {
		background-color: #fff;
		padding: 15px 55px;
		width: 100%;
		box-sizing: border-box;
		min-height: 100%;
	}

	.search_box {
		display: flex;
		align-items: center;
		font-size: 0.16rem;

		.search_item {
			display: flex;
			align-items: center;
			margin-right: 0.5rem;

		}
	}

	.box_cont {
		margin-top: 20px;
	}

	.btn_list {
		display: flex;
		align-items: center;
	}

	.tc_title {
		font-size: 0.18rem;
		font-weight: bold;
		color: #333;
		position: relative;
		padding-left: 20px;
		margin-bottom: 20px;
	}

	.tc_title:after {
		content: '';
		position: absolute;
		height: 100%;
		width: 4px;
		left: 0px;
		background-color: rgb(26, 144, 253);
	}

	::v-deep td {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell>.cell {
		text-align: center !important;
	}

	::v-deep .el-table th.el-table__cell.is-leaf {
		background-color: rgb(242, 242, 242);
		color: #777;
	}

	::v-deep .el-table th.el-table__cell.is-leaf,
	.el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table td.el-table__cell {
		border-bottom: none !important;
	}

	::v-deep .el-table {
		margin-top: 10px;
		border-left: none !important;
	}

	::v-deep .el-table__fixed {
		border-bottom: none;
	}

	::v-deep .el-table::before {
		background-color: #fff !important;
	}

	::v-deep .el-table__fixed::before {
		background-color: #fff !important;
	}

	::v-deep .el-dialog__header {
		display: flex !important;
	}

	::v-deep .el-dialog__footer span {
		display: flex !important;
		justify-content: space-around;
		width: 100%;
	}

	::v-deep .el-form-item__content {
		display: flex;
		flex-direction: column;
	}

	::v-deep .el-form-item__content {
		margin-left: 0 !important;
	}

	::v-deep .el-table__fixed-right::before {
		background-color: #fff !important;
	}

	.item_class {
		display: flex;
		align-items: center;
		width: 100%;
		box-sizing: border-box;

		.el-form-item {
			margin-right: 150px;
		}
	}

	::v-deep.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>